import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ash/code/blog/src/layouts/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I am a world-class software developer living in Atlantic Canada. For `}<a parentName="p" {...{
        "href": "/blog/5-years-of-ios/"
      }}>{`five years`}</a>{`, I was focused on iOS development, but `}<a parentName="p" {...{
        "href": "/blog/5-more-years-of-building-software/"
      }}>{`my area of interest broadened`}</a>{` and now I do many things. I’m keenly interested in `}<a parentName="p" {...{
        "href": "/blog/javascript-is-good-actually/"
      }}>{`TypeScript`}</a>{`, `}<a parentName="p" {...{
        "href": "/blog/the-case-for-react-native/"
      }}>{`ReactNative`}</a>{`, and `}<a parentName="p" {...{
        "href": "/blog/the-spirit-of-moya/"
      }}>{`GraphQL`}</a>{`. I’m married, with two cats. After `}<a parentName="p" {...{
        "href": "https://github.com/ashfurrow",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`contributing to open source`}</a>{` so much, `}<a parentName="p" {...{
        "href": "/blog/open-source-ideology/"
      }}>{`it brainwashed me`}</a>{`. I’ve `}<a parentName="p" {...{
        "href": "/books"
      }}>{`written books`}</a>{` and I’ve `}<a parentName="p" {...{
        "href": "https://photos.ashfurrow.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`made photos`}</a>{` and I’ve `}<a parentName="p" {...{
        "href": "/blog/just-play/"
      }}>{`played guitar`}</a>{`.`}</p>
    <p>{`Community is incredibly important to me; I’ve only been able to accomplish what I have because of the kindness and generosity of others. I owe it to the people who helped me to help others now, which is why I volunteered with `}<a parentName="p" {...{
        "href": "https://www.c4q.nyc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Coalition for Queens`}</a>{` and ran a `}<a parentName="p" {...{
        "href": "https://peerlab.community",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Peer Lab`}</a>{` every week in New York `}<a parentName="p" {...{
        "href": "/blog/5-years-of-peer-lab/"
      }}>{`for five years`}</a>{`.`}</p>
    <hr></hr>
    <p>{`If you want to get in touch, `}<a parentName="p" {...{
        "href": "https://tenforward.social/@ashfurrow",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Mastodon`}</a>{` is probably the best way to reach me. Only if you have to, you can email `}<a parentName="p" {...{
        "href": "mailto:ash@ashfurrow.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`ash@ashfurrow.com`}</a>{`.`}</p>
    <hr></hr>
    <p>{`Over my career, I’ve been fortunate to work at some amazing places.`}</p>
    <h3 {...{
      "id": "shopify",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#shopify",
        "aria-label": "shopify permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shopify`}</h3>
    <p>{`The ecommerce products I helped build at Artsy catered to the fine art market. `}<a parentName="p" {...{
        "href": "https://www.shopify.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Shopify`}</a>{`, in contrast, is working to “make commerce better for everyone.” It was the `}<em parentName="p">{`everyone`}</em>{` in that mission that resonated with me. It drew attention to a void that had grown in my heart.`}</p>
    <p>{`I worked on the `}<a parentName="p" {...{
        "href": "https://shop.app",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Shop app`}</a>{` since `}<a parentName="p" {...{
        "href": "/blog/joining-shopify/"
      }}>{`joining Shopify`}</a>{`. My deep expertise in React Native allowed me to push myself in many directions; my role as Senior Staff Developer afforded me the autonomy to decide where I would drive the most impact.`}</p>
    <p>{`My largest contribution was `}<a parentName="p" {...{
        "href": "https://shop.app/minis",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Shop Minis`}</a>{`, a React Native SDK for third parties to build native-quality shopping experiences. I held the technical vision for the SDK, even as the Shop app itself was iterating towards product-market fit. First I started building the SDK on my own, then I built up a team to deliver our internal prototype, then finally I oversaw two teams delivering a private alpha to launch partners. You can read more about `}<a parentName="p" {...{
        "href": "https://www.shopify.com/ca/editions/winter2023#shop-minis-sdk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`the public SDK launch here`}</a>{`.`}</p>
    <h3 {...{
      "id": "artsy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#artsy",
        "aria-label": "artsy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Artsy`}</h3>
    <p>{`I joined `}<a parentName="p" {...{
        "href": "https://www.artsy.net",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Artsy`}</a>{` in New York to help accomplish their ambitious goal: a future where art is as popular as music. That vision resonated with me, and for seven years I was proud to work towards it. After building Artsy software in TypeScript, Swift, Objective-C, Ruby, Scala – `}<a parentName="p" {...{
        "href": "/blog/perspective-of-the-polyglot/"
      }}>{`whatever it took`}</a>{` – I moved to technical leadership. I am most proud of leading the migration to `}<a parentName="p" {...{
        "href": "https://artsy.github.io/blog/2020/09/29/becoming-mobile-first-at-artsy/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Artsy’s mobile-first product strategy`}</a>{`.`}</p>
    <p>{`Most of the code I worked on `}<a parentName="p" {...{
        "href": "https://github.com/artsy",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`is open source`}</a>{` and I wrote a lot on `}<a parentName="p" {...{
        "href": "http://artsy.github.io",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Artsy’s Engineering Blog`}</a>{`.`}</p>
    <h3 {...{
      "id": "teehanlax",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#teehanlax",
        "aria-label": "teehanlax permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Teehan+Lax`}</h3>
    <p>{`After learning the importance of design at 500px, I moved to Canada’s leading design agency, `}<a parentName="p" {...{
        "href": "http://www.teehanlax.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Teehan+Lax`}</a>{`, to learn more. I developed keen collaboration skills while working closely with designers and clients to realize outstanding products.`}</p>
    <h3 {...{
      "id": "500px",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#500px",
        "aria-label": "500px permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`500px`}</h3>
    <p><a parentName="p" {...{
        "href": "https://500px.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`500px`}</a>{` was a site that aimed to host the world’s best photography. Like Flickr, but only for your `}<em parentName="p">{`best`}</em>{` shots. I developed their first iPad app, and later led the iOS team to ship the new universal app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      